import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  var cookieValue=Cookies.get(TokenKey)
  var localStorageValue =localStorage.getItem(TokenKey)
  return localStorageValue
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
  return Cookies.set(TokenKey, token)
   
}

export function removeToken() {
  localStorage.removeItem(TokenKey)
  
  return Cookies.remove(TokenKey)
}
