import request from "@/utils/request";

// 查询消息个人管理列表
export function listUser(query) {
  return request({
    url: "/business/user/mainPageList",
    method: "get",
    params: query,
  });
}
// 查询消息个人管理列表
export function login() {
  return request({
    url: "/login",
    method: "post",
    data: {
      username: "guangmingcun",
      password: "guangmingcun001",
    },
  });
}
//个人积分统计情况
export function personalIntegralStatistics(query) {
  return request({
    url: "/business/user/personalIntegralStatistics",
    method: "get",
    params: query,
  });
}
// 查询消息个人管理详细
export function getUser(id) {
  return request({
    url: "/business/user/" + id,
    method: "get",
  });
}

// 新增消息个人管理
export function addUser(data) {
  return request({
    url: "/business/user",
    method: "post",
    data: data,
  });
}

// 修改消息个人管理
export function updateUser(data) {
  return request({
    url: "/business/user",
    method: "put",
    data: data,
  });
}

// 删除消息个人管理
export function delUser(id) {
  return request({
    url: "/business/user/" + id,
    method: "delete",
  });
}
