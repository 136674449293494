import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import '@/styles/index.css';
// import './styles/element-variables.scss';

Vue.use(ElementUI);


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title;
  }
  next();
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
